"use client";

import { useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { Text } from "@/components/ui/Text";
import { type PageContent, type SupportedLanguage } from "@/utils/i18n";
import { getLinks } from "@/utils/nav-links";
import { cn } from "@/utils/tw";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useLocaleProvider } from "../LocaleProvider";
const t = {
  feedback: {
    en: "Give us feedback",
    no: "Gi oss tilbakemelding",
    dk: "Giv os feedback"
  }
} satisfies PageContent;
export default function TWNavClient({
  variant = "dark",
  businessSlug,
  language
}: {
  businessSlug?: string;
  language: SupportedLanguage;
  variant?: "dark" | "light";
}) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const pathname = usePathname();
  return <>
      <div className="flex lg:hidden">
        <button type="button" className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-inactive-gray" onClick={() => setMobileMenuOpen(true)}>
          <span className="sr-only">Open main menu</span>
          <Bars3Icon className={cn("h-6 w-6", variant === "dark" ? "text-white" : "text-primary-text")} aria-hidden="true" data-sentry-element="Bars3Icon" data-sentry-source-file="TWNavClient.tsx" />
        </button>
      </div>
      <Dialog as="div" className="transition-all lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen} data-sentry-element="Dialog" data-sentry-source-file="TWNavClient.tsx">
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className={cn("fixed inset-y-0 left-0 z-50 w-full overflow-y-auto p-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10", variant === "dark" ? "bg-primary-dark" : "bg-white")} data-sentry-element="unknown" data-sentry-source-file="TWNavClient.tsx">
          <div className="flex items-center justify-between">
            <a href={businessSlug ? `/profile/business/${businessSlug}` : "/"} className="-m-1.5 p-1.5" onClick={() => setMobileMenuOpen(false)}>
              <span className="sr-only">Vouch</span>
              <Image height={24} width={95} src={variant === "dark" ? "/logo-light.svg" : "/logo.svg"} alt="Vouch" data-sentry-element="Image" data-sentry-source-file="TWNavClient.tsx" />
            </a>
            <button type="button" className="-m-2.5 rounded-md p-2.5 text-inactive-gray" onClick={() => setMobileMenuOpen(false)}>
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" data-sentry-element="XMarkIcon" data-sentry-source-file="TWNavClient.tsx" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="flex flex-col gap-4 py-6">
                {getLinks(language, businessSlug).map(link => <Link href={link.href} key={link.label} onClick={() => {
                setMobileMenuOpen(false);
              }} className={cn("py-1", pathname?.endsWith(link.href) ? cn("underline underline-offset-4", variant === "dark" ? "decoration-white" : "") : "")}>
                    <Text.P inverted={variant === "dark"} small>
                      {link.label}
                    </Text.P>
                  </Link>)}
                <div>
                  <Link href="https://chat.buility.com?cid=661925ba2db8330010127953" target="_blank" className="rounded-sm border border-primary px-2 py-1 text-sm text-primary" data-sentry-element="Link" data-sentry-source-file="TWNavClient.tsx">
                    {t.feedback[language]}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </>;
}
export function DesktopNavLinks(props: {
  businessSlug?: string;
  variant?: "dark" | "light";
}) {
  const {
    lang
  } = useLocaleProvider();
  const pathname = usePathname();
  return getLinks(lang, props.businessSlug).map(link => <Link href={link.href} key={link.label} className={"font-bold transition-all"}>
      <Text.P inverted={props.variant === "dark" ? true : undefined} className={pathname?.endsWith(link.href) ? cn(props.variant === "dark" ? "text-white" : "text-primary-text") : cn("text-muted", props.variant === "dark" ? "hover:text-white" : "hover:text-primary-text")} small>
        {link.label}
      </Text.P>
    </Link>);
}