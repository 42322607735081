import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default","DesktopNavLinks"] */ "/workspace/src/app/[lang]/(dynamic)/TWNavClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserMenu"] */ "/workspace/src/app/[lang]/(dynamic)/UserMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeroSection"] */ "/workspace/src/app/[lang]/(static)/(home)/HeroSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HouseAnimation"] */ "/workspace/src/app/[lang]/(static)/(home)/HouseAnimation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Newsletter"] */ "/workspace/src/app/[lang]/(static)/(home)/Newsletter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LocaleSelector"] */ "/workspace/src/components/LocaleSelector.tsx");
