"use client";

import { Animation } from "@/components/ui/Animation";
import { Button } from "@/components/ui/Button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/Form";
import { Input } from "@/components/ui/Input";
import { Loader } from "@/components/ui/Loader/Loader";
import { Text } from "@/components/ui/Text";
import { useToast } from "@/components/ui/use-toast";
import { defaultFormErrorToast } from "@/utils/formErrorToast";
import { type PageContent } from "@/utils/i18n";
import { trpc } from "@/utils/TrpcProvider";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useLocaleProvider } from "../../LocaleProvider";
const t = {
  form: {
    heading: {
      no: "Få ukentlige tips om stillinger!",
      en: "Get weekly tips about new listings!",
      dk: "Få ugentlige tips om stillinger!"
    },
    body: {
      no: "Meld deg på vårt nyhetsbrev og få aktuelle stillinger rett i innboksen.",
      en: "Sign up for our newsletter and get relevant listings straight to your inbox.",
      dk: "Tilmeld dig vores nyhedsbrev og få relevante stillinger direkte i din indbakke."
    },
    email: {
      no: "E-post",
      en: "Email",
      dk: "E-mail"
    },
    buttonCta: {
      no: "Meld deg på",
      en: "Sign up",
      dk: "Tilmeld dig"
    }
  },
  thanks: {
    title: {
      en: "You are now registered for our newsletter!",
      no: "Du er nå registrert for vårt nyhetsbrev!",
      dk: "Du er nu tilmeldt vores nyhedsbrev!"
    }
  }
} satisfies PageContent;
const formSchema = z.object({
  email: z.string().email()
});
type Form = z.infer<typeof formSchema>;
export function Newsletter() {
  const {
    toast
  } = useToast();
  const {
    lang,
    country
  } = useLocaleProvider();
  const signUpNewsletter = trpc.misc.registerNewsletter.useMutation({
    onSuccess() {
      toast({
        title: t.thanks.title[lang]
      });
      form.reset({
        email: ""
      });
    },
    onError: er => {
      toast({
        title: er.message,
        variant: "destructive"
      });
    }
  });
  const form = useForm<Form>({
    resolver: zodResolver(formSchema)
  });

  // 2. Define a submit handler.
  function onSubmit(values: z.infer<typeof formSchema>) {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.
    signUpNewsletter.mutate({
      ...values,
      country
    });
  }
  return <div className="flex w-full flex-col-reverse justify-between gap-8 py-24 md:flex-row lg:py-32" data-sentry-component="Newsletter" data-sentry-source-file="Newsletter.tsx">
      <Form {...form} data-sentry-element="Form" data-sentry-source-file="Newsletter.tsx">
        <form onSubmit={form.handleSubmit(onSubmit, defaultFormErrorToast)} className="ml-0 flex max-w-xl flex-col items-start gap-4">
          <Text.H5 data-sentry-element="unknown" data-sentry-source-file="Newsletter.tsx">{t.form.heading[lang]}</Text.H5>
          <Text.P className="mb-12" data-sentry-element="unknown" data-sentry-source-file="Newsletter.tsx">{t.form.body[lang]}</Text.P>
          {signUpNewsletter.isLoading && <Loader />}
          <FormField control={form.control} name="email" render={({
          field
        }) => <FormItem className="w-full">
                <FormLabel>{t.form.email[lang]}</FormLabel>
                <FormControl>
                  <Input placeholder={t.form.email[lang]} {...field} type="email" />
                </FormControl>

                <FormMessage />
              </FormItem>} data-sentry-element="FormField" data-sentry-source-file="Newsletter.tsx" />
          <Button type="submit" className="self-start" icon="rightArrow" size="lg" variant="primary-light" fancy disabled={!form.formState.isValid} data-sentry-element="Button" data-sentry-source-file="Newsletter.tsx">
            {t.form.buttonCta[lang]}
          </Button>
        </form>
      </Form>
      <Animation url={"/img/newsletter.json"} className="mx-auto h-[160px] md:mx-0 md:h-auto md:w-[377px]" loop={false} autoplay={false} interactivity={{
      mode: "scroll",
      actions: [{
        visibility: [0, 0.3],
        type: "seek",
        frames: [0]
      }, {
        visibility: [0.3, 0.6],
        type: "seek",
        frames: [0, 52]
      }, {
        visibility: [0.6, 1],
        type: "seek",
        frames: [52]
      }]
    }} data-sentry-element="Animation" data-sentry-source-file="Newsletter.tsx" />
    </div>;
}