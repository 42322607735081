import { type PageContent, type SupportedLanguage } from "./i18n";

const t = {
  business: {
    no: "For bedrifter",
    en: "For companies",
    dk: "For virksomheder",
  },
  listings: {
    no: "Ledige stillinger",
    en: "All listings",
    dk: "Alle stillinger",
  },
  pricing: {
    no: "Priser",
    en: "Pricing",
    dk: "Priser",
  },
  myListings: {
    no: "Stillinger",
    en: "Listings",
    dk: "Stillinger",
  },
  about: {
    no: "Om oss",
    en: "About us",
    dk: "Om os",
  },
  blog: {
    no: "Blogg",
    en: "Blog",
    dk: "Blog",
  },
  companyPage: {
    no: "Bedriftside",
    en: "Company page",
    dk: "Virksomhedsside",
  },
} satisfies PageContent;

export const getLinks = (language: SupportedLanguage, businessSlug?: string) => {
  const languageStrings = t;

  return businessSlug
    ? [
        {
          href: `/profile/business/${businessSlug}/listings`,
          label: languageStrings.myListings[language],
        },
        {
          href: `/profile/business/${businessSlug}/settings/content`,
          label: languageStrings.companyPage[language],
        },
        {
          href: "https://playbook.vouch.careers/",
          label: "Playbooks",
        },
      ]
    : [
        {
          href: "/discovery",
          label: languageStrings.listings[language],
        },
        {
          href: "/business",
          label: languageStrings.business[language],
        },
        {
          href: "/pricing",
          label: languageStrings.pricing[language],
        },
        {
          href: "/about",
          label: languageStrings.about[language],
        },
        {
          href: "/blog",
          label: languageStrings.blog[language],
        },
      ];
};
