"use client";

import { Animation } from "@/components/ui/Animation";
export const HeroSection = () => {
  return <div className="flex h-full items-center justify-center" data-sentry-component="HeroSection" data-sentry-source-file="HeroSection.tsx">
      <div className="group pointer-events-none flex h-full items-center justify-center">
        <div className="invisible absolute mx-auto h-full w-auto group-hover:visible">
          <svg width="175" height="703" viewBox="0 0 175 703" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-auto h-full fill-[#FFF1D2]" data-sentry-element="svg" data-sentry-source-file="HeroSection.tsx">
            <path d="M142.823 0H36.6936L0.0710845 690.15C0.0298141 690.927 0.545983 691.625 1.30176 691.813C57.2231 705.73 115.683 705.896 171.683 692.295L173.667 691.813C174.441 691.625 174.973 690.915 174.936 690.119L142.823 0Z" data-sentry-element="path" data-sentry-source-file="HeroSection.tsx" />
          </svg>
        </div>
        <Animation url={"/img/blinker.json"} className="pointer-events-auto flex h-full max-h-full max-w-full group-hover:hidden" data-sentry-element="Animation" data-sentry-source-file="HeroSection.tsx" />
        <Animation url={"/img/dancer.json"} className="pointer-events-auto hidden h-full max-h-full max-w-full group-hover:flex" data-sentry-element="Animation" data-sentry-source-file="HeroSection.tsx" />
      </div>
    </div>;
};